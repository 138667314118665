import { AdditionalData } from '@monkvision/types';

export enum TeslaInspectionStatus {
  INSPECTION_LINK_SEND = 'inspection_link_send',
  ONGOING_CAPTURE = 'ongoing_capture',
  READY_FOR_REVIEW = 'ready_for_review',
  PDF_SENT = 'pdf_sent',
  FINALIZED = 'finalized',
}

export enum TeslaCountry {
  UK = 'UK',
  NL = 'NL',
  FR = 'FR',
  US = 'US',
  DE = 'DE',
}

export interface TeslaAdditionalData extends AdditionalData {
  country?: TeslaCountry;
  lease_end_date?: string; // ISO 8601
  customer_status?: TeslaInspectionStatus;
  last_update_date?: string; // ISO 8601
  inspection_start?: string; // ISO 8601
  inspection_end?: string; // ISO 8601
}
