import { useCallback } from 'react';
import { getEnvOrThrow } from '@monkvision/common';
import ky from 'ky';

const TOKEN_SERVICE_DOMAIN = getEnvOrThrow('REACT_APP_TOKEN_SERVICE_DOMAIN');

interface GetTokenResponse {
  token: string;
}

export function useGetCaptureToken() {
  const getCaptureToken = useCallback(async (inspectionId: string) => {
    const result = await ky.get<GetTokenResponse>(
      `https://${TOKEN_SERVICE_DOMAIN}?inspection_id=${inspectionId}`,
    );
    const { token } = await result.json();
    return token;
  }, []);

  return { getCaptureToken };
}
