import { PropsWithChildren, useEffect } from 'react';
import { MonkThemeProvider, useMonkAppState, useMonkTheme } from '@monkvision/common';
import { useAppRegion } from '../contexts';
import { getAuthTokenRegion } from '../auth';

function RootStylesContainer({ children }: PropsWithChildren<unknown>) {
  const { rootStyles } = useMonkTheme();

  return (
    <div className='app-container' style={rootStyles}>
      {children}
    </div>
  );
}

export function AppContainer({ children }: PropsWithChildren<unknown>) {
  const { setRegion } = useAppRegion();
  const { authToken, config } = useMonkAppState();

  useEffect(() => {
    const region = getAuthTokenRegion(authToken);
    if (region) {
      setRegion(region);
    }
  }, [authToken]);

  return (
    <MonkThemeProvider palette={config.palette}>
      <RootStylesContainer>{children}</RootStylesContainer>
    </MonkThemeProvider>
  );
}
