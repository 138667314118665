import { useTranslation } from 'react-i18next';
import { useAsyncEffect, useIsMounted, useLoadingState, useMonkAppState } from '@monkvision/common';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeMonkJwt, isTokenExpired } from '@monkvision/network';
import { useMonitoring } from '@monkvision/monitoring';
import { useAnalytics } from '@monkvision/analytics';
import { Button, Spinner } from '@monkvision/common-ui-web';
import { AppRegion, useAppRegion } from '../../contexts';
import styles from './LoginPage.module.css';
import { Page } from '../pages';
import { useRegionalAuth } from '../../auth';
import { useGetCaptureToken } from '../../hooks';

function getLoginErrorMessage(err: unknown): string {
  if (err instanceof Error) {
    if (err.message === 'Popup closed') {
      return 'login.errors.popup-closed';
    }
  }
  return 'login.errors.unknown';
}

export function LoginPage() {
  const { region, setRegion } = useAppRegion();
  const { authToken, setAuthToken, config, inspectionId } = useMonkAppState();
  const regionalAuth = useRegionalAuth();
  const { t } = useTranslation();
  const loading = useLoadingState();
  const analytics = useAnalytics();
  const { handleError, setUserId } = useMonitoring();
  const { getCaptureToken } = useGetCaptureToken();
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const isExpired = useMemo(() => authToken && isTokenExpired(authToken), [authToken]);

  useAsyncEffect(
    () => {
      if (authToken || !inspectionId) {
        return Promise.resolve(null);
      }
      loading.start();
      return getCaptureToken(inspectionId);
    },
    [inspectionId, getCaptureToken, authToken, setAuthToken],
    {
      onResolve: (token) => {
        loading.onSuccess();
        if (token) {
          setAuthToken(token);
        }
      },
      onReject: (err) => {
        loading.onError('login.errors.unknown');
        handleError(err);
      },
    },
  );

  useEffect(() => {
    if (!inspectionId && !config.allowManualLogin) {
      loading.onError('login.errors.missing-param');
    }
    if (authToken && !isExpired) {
      navigate(Page.CREATE_INSPECTION);
    }
  }, [authToken, isExpired, config, inspectionId, loading]);

  const regionalLogin = async (appRegion: AppRegion) => {
    loading.start();
    try {
      const token = await regionalAuth[appRegion].login();
      navigate(Page.CREATE_INSPECTION);
      if (isMounted()) {
        loading.onSuccess();
        const userId = token ? decodeMonkJwt(token) : undefined;
        if (userId?.sub) {
          setUserId(userId.sub);
          analytics.setUserProperties({ authToken: userId.sub });
        }
      }
    } catch (err) {
      if (isMounted()) {
        const message = getLoginErrorMessage(err);
        loading.onError(message);
      }
      handleError(err);
    }
  };

  const handleEULogIn = async () => {
    setRegion(AppRegion.EU);
    regionalLogin(AppRegion.EU).catch(() => {});
  };

  const handleUSLogIn = async () => {
    setRegion(AppRegion.US);
    regionalLogin(AppRegion.US).catch(() => {});
  };

  return (
    <div className={styles['container']}>
      {loading.isLoading && <Spinner size={80} />}
      {!loading.isLoading && isExpired && (
        <div className={styles['errorMessage']}>{t('login.errors.token-expired')}</div>
      )}
      {!loading.isLoading && loading.error && (
        <div className={styles['errorMessage']}>{t(loading.error as string)}</div>
      )}
      {!loading.isLoading && config.allowManualLogin && (
        <div className={styles['buttonsContainer']}>
          {authToken && (
            <Button
              primaryColor='alert'
              loading={loading}
              onClick={regionalAuth[region ?? AppRegion.EU].logout}
            >
              {t('login.logout')}
            </Button>
          )}
          {!authToken && (
            <>
              <Button onClick={handleEULogIn} className={styles['loginButton']} loading={loading}>
                {`🇪🇺 ${t('login.eu')}`}
              </Button>
              <Button onClick={handleUSLogIn} className={styles['loginButton']} loading={loading}>
                {`🇺🇸 ${t('login.us')}`}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
