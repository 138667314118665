import ReactDOM from 'react-dom';
import { MonitoringProvider } from '@monkvision/monitoring';
import { AnalyticsProvider } from '@monkvision/analytics';
import { sentryMonitoringAdapter } from './sentry';
import { posthogAnalyticsAdapter } from './posthog';
import { AppRouter } from './app';
import { AppRegionProvider } from './contexts';
import { AuthProvider } from './auth';
import './i18n';
import './index.css';

ReactDOM.render(
  <MonitoringProvider adapter={sentryMonitoringAdapter}>
    <AnalyticsProvider adapter={posthogAnalyticsAdapter}>
      <AppRegionProvider>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </AppRegionProvider>
    </AnalyticsProvider>
  </MonitoringProvider>,
  document.getElementById('root'),
);
